import { Injectable } from '@angular/core';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private subscriptions: Map<string, any> = new Map();

  constructor() {}

  startSubscription(id: string, interval_: number, obtenerDatos: () => void) {
    const subscription = interval(interval_).subscribe(() => {
      obtenerDatos();
    });
    this.subscriptions.set(id, subscription);
  }

  stopSubscription(id: string) {
    const subscription = this.subscriptions.get(id);
    if (subscription) {
      subscription.unsubscribe();
      this.subscriptions.delete(id);
    }
  }
}