import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  baseUrl = 'https://saludplusweb.com/wp-json/wp/v2/posts';
  limit = 4;
  private httpClient: HttpClient;
  constructor(handler: HttpBackend, private http: HttpClient) {
    this.httpClient = new HttpClient(handler);
  }

  /**
   * Obtener noticias
   */
  async getNews(): Promise<any[]> {
    const data = this.httpClient.get<any[]>(`${this.baseUrl}?per_page=${this.limit}`)
    const dataResponse = await lastValueFrom(data);
  return dataResponse;
  }

  CargarListadoDashboard<T>(): Observable<SaludPlusApiResponse<T>> { 
    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Dashboard.Dashboard}/Listado`,
    );
  }

}