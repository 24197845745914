import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { ConfiguracionUsuarioKeys, ConfiguracionUsuarioDTO } from '@src/app/models/General/ConfiguracionUsuario';
import { RequestResult } from '@src/app/models/General/RequestModel';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { lastValueFrom, Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class DashboardIncidenciasService {

  constructor(private http: HttpClient) { }

  ObtenerDashBoard<T>(): Observable<SaludPlusApiResponse<T>> {
    
    let fechaHace7Dias = new Date();
    fechaHace7Dias.setDate(fechaHace7Dias.getDate() - 7);
    let fechaFormateada = fechaHace7Dias.toISOString().slice(0,10).replace(/-/g,"/")

    let params = new HttpParams().set("periodo", fechaFormateada);

    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Dashboard.DashboardIncidencias}`,
      {
        params: params,
      }
    );
  }
}
