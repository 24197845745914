import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { ConfiguracionUsuarioKeys, ConfiguracionUsuarioDTO } from '@src/app/models/General/ConfiguracionUsuario';
import { RequestResult } from '@src/app/models/General/RequestModel';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { lastValueFrom, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class DashboardAsistencialService {

  constructor(private http: HttpClient) { }
   

  /**
   * Obtener
   * @param idServicio    id del servicio por el que se filtrará este id no es obligatorio
   * @param idHabitacion  id de la habitación por la que se filtrará este id no es obligatorio
   */
  ObtenerDashBoard<T>(idServicio?: number, idHabitacion?: number): Observable<SaludPlusApiResponse<T>> {

    if (idHabitacion === undefined && idServicio === undefined) {
      return this.http.get<SaludPlusApiResponse<T>>(
        `${ControllerApiList.Dashboard.DashboardAsistencial}`
      );
    } else if (idHabitacion === undefined && idServicio !== undefined) {
      let params = new HttpParams().set("idServicio", idServicio)

      return this.http.get<SaludPlusApiResponse<T>>(
        `${ControllerApiList.Dashboard.DashboardAsistencial}`,
        {
          params: params,
        }
      );
    } else if (idHabitacion !== undefined && idServicio === undefined) {
      let params = new HttpParams().set("idHabitacion", idHabitacion)

      return this.http.get<SaludPlusApiResponse<T>>(
        `${ControllerApiList.Dashboard.DashboardAsistencial}`,
        {
          params: params,
        }
      );
    } else {
      let params = new HttpParams().set("idServicio", idServicio).set("idHabitacion", idHabitacion)

      return this.http.get<SaludPlusApiResponse<T>>(
        `${ControllerApiList.Dashboard.DashboardAsistencial}`,
        {
          params: params,
        }
      );
    }

  }
}
