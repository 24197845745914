import { Component, OnInit,  ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HomeService } from '@src/app/pages/home/services/home.service';
import { HomeDTO } from '@src/app/models/Home/HomeDTO';
import { NGXLogger } from 'ngx-logger';
import { SplusLogger } from '@src/app/services/Utilities/splus.logger.service';

 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
   
  public HomeDashboard: HomeDTO[] = []; 

  constructor(
    private logger: SplusLogger,
    private titleService: Title, 
    private route: ActivatedRoute,
    private homeService: HomeService
  ) { 
   /* this.logger.debug("Debug message Prueba");
    this.logger.info("Info message Prueba" );
    this.logger.log("Default log message Prueba");
    this.logger.warn("Warning message Prueba");
    this.logger.error("Error message Prueba");*/
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    this.homeService.CargarListadoDashboard<HomeDTO[]>().subscribe(res => {
      if (res.isSuccessful) {
        if (res.result) {
          this.HomeDashboard = res.result; 
        }
      }
    });
  }

 
 
 





}
