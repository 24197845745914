<div class="max-h-full overflow-auto ">
  <kendo-appbar [hidden]="!ShowFilters" position="top">

    <kendo-appbar-spacer width="10px"></kendo-appbar-spacer>



    <kendo-appbar-section>

      <div class="relative max-w-md w-full">
        <div class="absolute top-1 left-2 inline-flex items-center p-2">
          <i class="fas fa-search text-gray-400"></i>
        </div>
        <input [(ngModel)]="pacienteFilter"
          class="w-full h-10 pl-10 pr-4 py-1 text-base placeholder-gray-500 border rounded-full focus:shadow-outline"
          type="search" placeholder="Buscar...">
      </div>



    </kendo-appbar-section>


    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>

      <span class="text-white pr-1">Servicio</span>
      <kendo-dropdownlist class="w-164 m-2" #selectServicio [data]="dataServicio" [defaultItem]="defaultItemServicio"
        textField="nombreServicio" valueField="idServicio" [value]="selectedValueServicio"
        (valueChange)="handleServicioChange($event)" [valuePrimitive]="true">
      </kendo-dropdownlist>
    </kendo-appbar-section>


    <kendo-appbar-section>
      <span class="text-white pr-1">Habitación</span>
      <kendo-dropdownlist class="w-164 m-2" #selectHabitacion [data]="dataHabitacionFilter"
        [disabled]="isDisabledHabitacion" [defaultItem]="defaultItemHabitacion" [(ngModel)]="selectedValueHabitacion"
        textField="nombreHabitacion" valueField="idHabitacion" [value]="selectedValueHabitacion"
        [valuePrimitive]="true">
      </kendo-dropdownlist>
    </kendo-appbar-section>



  </kendo-appbar>

  <div class="loading-card" *ngIf="isLoading">
    <span class="loading-card-container" role="progressbar" aria-label="Loading">
      <span class="css-uno-barra-loading-l"></span>
      <span class="css-dos-barra-loading-l"></span>
      <span class="css-tres-barra-loading-l"></span>
      <span class="css-cuatro-barra-loading-l"></span>
    </span>
  </div>




  <div id="principal" class="max-h-full w-full flex flex-col lg:flex-row">
    <div id="container-ocupados" class=" grow max-h-full w-full lg:w-3/4 p-2">
      <div class="gap-2 grid grid-cols-auto" style=" grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); ">

        <div
          *ngFor="let item of data | servicioFilter:selectedValueServicio:selectedValueHabitacion:selectedValueAdmision:pacienteFilter; let i = index;trackBy: trackByFn">
          
          <kendo-contextmenu [target]="target" [items]="caracteristicasAsistencial" showOn="click"
            *ngIf="item.idAdmision">

            <ng-template kendoMenuItemTemplate let-itemMenu="item">
              <div class="block w-full color"
                (click)="OpenWin( itemMenu.ruta ? itemMenu.ruta : '/' + itemMenu.controladorCaracteristica + '/' + itemMenu.funcionCaracteristica + '?idAdmision=' + item.idAdmision , itemMenu.nombre )">
                <i [ngClass]="itemMenu.claseGraficaCssCaracteristica" class="text-asistencial"></i>
                {{ itemMenu.nombre }}
              </div>
            </ng-template>

          </kendo-contextmenu>


          <div class="sm:w-full rounded-lg shadow-lg bg-white dark:bg-oscuro-alternativo  dashboard-asistencial-card">
            <div class="flex justify-between border-l-4 rounded-t px-3 py-1 " [ngClass]="{
      'border-red-500': item.colorTriage === 'ROJO',
      'border-orange-500': item.colorTriage === 'NARANJA',
      'border-yellow-500': item.colorTriage === 'AMARILLO',
      'border-green-500': item.colorTriage === 'VERDE',
      'border-blue-500': item.colorTriage === 'AZUL'
    }">

              <div>
                <div>
                  <span class="font-bold text-normal text-m dark:text-white">{{item.nombreCama | titlecase }}</span>
                </div>
                <div>
                  <span class="text-gray-700 dark:text-white text-sm  ">{{item.nombreServicio | titlecase}} |
                    {{item.nombreHabitacion |
                    titlecase}}</span>
                </div>
              </div>
              <div>

                <button kendoButton #target class="target rounded-sm shadow-sm border border-gray-100"
                  [hidden]="!item.idAdmision" [disabled]="isEditable">
                  <i class="fa fa-address-book text-asistencial"></i>
                </button>

              </div>
            </div>

            <div class="px-1 text-gray-600 border-b border-t dark:border-gray-700">


              <div class=" ">
                <div class="flex flex-col  items-center    sm:h-full sm:justify-center">

                  <div class="flex flex-col space-y-2 p-1 text-gray-600 w-full ">


                    <div class="flex flex-row text-sm">
                      <span class="mr-3  text-gray-500 dark:text-gray-300">
                        <i class="fa-regular fa-user"></i>
                      </span>
                      <div
                        class="items-center line-clamp-1 text-gray-500 font-bold  dark:text-gray-300 dark:font-normal">
                        <div [title]="item.nombrePaciente | titlecase"> {{item.nombrePaciente | titlecase}}</div>
                      </div>
                    </div>

                    <div class="flex flex-row text-sm">
                      <span class="mr-3  text-gray-500 dark:text-gray-300">
                        <i class="fa-regular fa-address-card"></i>
                      </span>
                      <div class="items-center  text-gray-500 dark:text-gray-300">
                        <div class="text-sm"> {{item.tipoDocumentoPaciente}} {{item.documentoPaciente}} &nbsp;&nbsp; <i
                            class="fa-solid fa-venus-mars"></i> {{ item.sexoPaciente == 2 ? "Masculino" : "Femenino"}}
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row text-sm">
                      <span class="mr-3  text-gray-500 dark:text-gray-300">
                        <i class="fa-solid fa-calendar-check "></i></span>
                      <div class="flex items-center  text-gray-500 dark:text-gray-300">

                        <span class="año-edad" [ngSwitch]="item.edadAnnos">
                          <ng-container *ngSwitchCase="0">
                            0 año
                          </ng-container>
                          <ng-container *ngSwitchCase="1">
                            1 año
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            {{item.edadAnnos}} años
                          </ng-container>
                        </span>
                        <span>
                          &nbsp; {{item?.edad | split:"-":1}}
                        </span>
                        <span>&nbsp; {{item?.edad | split:"-":2}}</span>


                      </div>
                    </div>
                    <div class="text-sm  text-gray-500 dark:text-gray-300">


                      <div> <span class="mr-3">
                          <i class="fa fa-building"></i></span> {{item.nombreEntidad | titlecase}}</div>
                      <div class="text-sm"> {{item.nombreContrato | titlecase}}</div>
                    </div>

                  </div>
                  <div class="flex flex-col w-full relative bottom-0">

                    <div class="  text-[#0ed3cf]  bg-gray-50 dark:bg-transparent py-1">

                      <div class="font-bold">
                        Admisión
                      </div>
                      <div class="flex items-center justify-between">
                        <div>
                          <i class="fa fa-check-square"></i> {{item.numeroAdmision}}
                        </div>
                        <div>
                          <i class="fa-regular fa-calendar-check"></i> {{item.fechIngreso}}
                        </div>
                        <div>
                          <i class="fa-regular fa-clock"></i> {{item.fechIngreso}}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>


            </div>

            <div class="p-2 pt-1 cursor-pointer hover:bg-gray-200 dark:hover:bg-neutral-800 "
              (click)="isEditable ? false : VerSignosVitales(item?.signosVitales)">
              <div class="row items-end">

                <div *ngIf="errorData">
                  <div class="text-40 opacity-50 text-center">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                  </div>
                  <div class="text-18 opacity-50 text-center">
                    Se presentó un problema con esta información.
                  </div>
          
                </div>
          
                <div *ngIf="!isLoading && data?.length == 0">
                  <div class="text-18 opacity-50 text-center">
                    No hay datos disponibles.
                  </div>
          
                </div>


                <ng-container *ngFor="let itemsignosVitales of item?.signosVitales">

                  <ng-container [ngSwitch]="itemsignosVitales.vitalsign">

                    <div *ngSwitchCase="'FC'" class="relative text-lg">
                      <span class="bold text-lg"> {{itemsignosVitales?.values[itemsignosVitales?.values?.length - 1]
                        }}</span>

                      <i class="fa-solid fa-heart text-red-600 absolute mt-[-6px]"></i>
                    </div>

                    <div *ngSwitchCase="'FR'" class="relative">
                      <span class="bold text-lg">{{itemsignosVitales?.values[itemsignosVitales?.values?.length -
                        1]}}</span>
                      <span class="text-xs"> RMP</span>
                      <span class="absolute mt-[-8px]">
                        <i class="fa-duotone fa-lungs text-cyan-400  text-lg"></i>
                      </span>
                    </div>

                    <div *ngSwitchCase="'T'" class="relative">
                      <span>{{itemsignosVitales?.values[itemsignosVitales?.values?.length - 1]}} C</span>

                      <span class="absolute mt-[-16px]">
                        <i class="fa-duotone fa-temperature-half text-orange-500 text-lg "></i>
                      </span>

                    </div>

                    <div *ngSwitchCase="'TA'" class="relative">

                      <span class="presion-1">
                        {{itemsignosVitales?.values[itemsignosVitales?.values?.length - 1] | split:"|":0}}
                      </span>
                      <div class="border-t border-gray-200"></div>

                      {{itemsignosVitales?.values[itemsignosVitales?.values?.length - 1] | split:"|":1}}
                      <span class="text-xs">mmHg</span>
                      <span class="absolute mt-[-24px] ml-[-26px]">
                        <i class="fa-solid fa-droplet text-red-600"></i>
                      </span>

                    </div> 
                    <div *ngSwitchCase="'SO2'" class="relative">
  
                      <span> {{itemsignosVitales?.values[itemsignosVitales?.values?.length - 1]}}</span>
                      <span class="sub-oxigeno">%</span>

                      <span class="absolute mt-[-24px] ml-[-26px]">
                        <span class="fa-stack">
                          <i class="fa-duotone fa-leaf text-base text-green-600"></i> 
                         </span>
                        
                      </span> 
                    </div>


                  </ng-container>

                  
         
                </ng-container>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div id="container-disponibles" class="flex-none w-full lg:w-1/4  min-w-[315px] p-1   bg-white dark:bg-neutral-700">
          <!-- disponibles -->
     
          
          <div class="min-w-max w-full">
            <div class="flex item justify-between bg-gray-200 dark:bg-oscuro-alternativo text-gray-600 dark:text-white uppercase text-sm leading-normal">
              <div class="py-1 px-1 text-left">Cama Disponible</div>
              <div class="py-1 px-1 text-left">Servicio</div>
            </div>
          
            <div *ngFor="let item of dataCamasDisponibles" class="mt-1 row border-b border-gray-200 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-800">
              <div class="py-1 px-1 text-left whitespace-nowrap">
                <div class="flex flex-col item-c">
                  <span class="font-medium">{{item.nombreCama | titlecase }}</span>
                  <span>{{item.nombreHabitacion | titlecase}}</span>
                </div>
              </div>
          
              <div class="px-1 text-left">
                <div class="flex flex-col items-center">
                  <span
                    [ngClass]="{'bg-yellow-200 text-yellow-600' : !item.sexoCama, 'bg-purple-200 text-purple-600' : item.sexoCama == 1, 'bg-blue-200 text-blue-600' : item.sexoCama == 2 }"
                    class="me-2 px-2.5 py-0.5 rounded text-xs font-medium">
                    {{item.edadMinima}} a {{item.edadMaxima}} años
                  </span>
                  
                  
                  <span>{{item.nombreServicio | titlecase}} </span>
                </div>
              </div>
            </div>
          </div>
 
    </div>
  </div>

 
</div>
<kendo-dialog title="Signos Vitales" *ngIf="opened" maxWidth="95%" [minWidth]="250" maxHeight="95%"
  (close)="close('cancel')">
  <kendo-stacklayout orientation="vertical" [align]="{ vertical: 'top' }">
    <kendo-stacklayout orientation="horizontal">
      <div class="box nav">
        <div class="title-chart-cardiaco">
          <i class="ico fa-solid fa-heart"></i>
          Frecuencia Cardiaca
        </div>
        <kendo-chart>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="signosVitales | signosVitalesCategoria:'FC'"
              [title]="{ text: 'Lecturas' }">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="line" color="#b20000" [style]="'normal'"
              [data]="signosVitales | signosVitales:'FC' ">
            </kendo-chart-series-item>

          </kendo-chart-series>
          <kendo-chart-tooltip></kendo-chart-tooltip>
        </kendo-chart>
      </div>
      <div class="box content">

        <div class="title-chart-rmp">
          <i class="fa-duotone fa-lungs text-cyan-400  text-2xl"></i>
          Frecuencia Respiratoria
        </div>

        <kendo-chart>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="signosVitales | signosVitalesCategoria:'FR'"
              [title]="{ text: 'Lecturas' }">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="line" [style]="'normal'" color="#0bcbce"
              [data]="signosVitales | signosVitales:'FR' ">
            </kendo-chart-series-item>

          </kendo-chart-series>
          <kendo-chart-tooltip></kendo-chart-tooltip>
        </kendo-chart>

      </div>

      <div class="box content">

        <div class="title-chart-temperatura">
          <i class="fa-duotone fa-temperature-half text-orange-500 text-2xl "></i>
          Temperatura
        </div>

        <kendo-chart>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="signosVitales | signosVitalesCategoria:'T'"
              [title]="{ text: 'Lecturas' }">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="line" [color]="'#ff9800'" [style]="'normal'"
              [data]="signosVitales | signosVitales:'T' ">
            </kendo-chart-series-item>

          </kendo-chart-series>
          <kendo-chart-tooltip></kendo-chart-tooltip>
        </kendo-chart>
      </div>


    </kendo-stacklayout>

    <kendo-stacklayout orientation="horizontal">



      <div class="box content">

        <div class="title-chart-presion-arterial">
          <i class="fa-solid fa-droplet text-red-600 text-2xl" ></i> Presión Arterial
        </div>
        <kendo-chart>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="signosVitales | signosVitalesCategoria:'TA'"
              [title]="{ text: 'Lecturas' }">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="line" [style]="'normal'" [data]="signosVitales | signosVitales:'TA': 0 ">
            </kendo-chart-series-item>

            <kendo-chart-series-item type="line" [style]="'normal'" [data]="signosVitales | signosVitales:'TA' : 1  ">
            </kendo-chart-series-item>

          </kendo-chart-series>
          <kendo-chart-tooltip></kendo-chart-tooltip>
        </kendo-chart>

      </div>
      <div class="box toc">
        <div class="title-chart-oxigeno">
          <i class="fa-duotone fa-leaf  text-green-600 text-2xl"></i>  Saturación de Oxígeno
        </div>
        <kendo-chart>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="signosVitales | signosVitalesCategoria:'SO2'"
              [title]="{ text: 'Lecturas' }">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="line" color="#a3d900" [style]="'normal'"
              [data]="signosVitales | signosVitales:'SO2' ">
            </kendo-chart-series-item>

          </kendo-chart-series>
          <kendo-chart-tooltip></kendo-chart-tooltip>
        </kendo-chart>

      </div>

      <div class="box toc">
        <kendo-chart [title]="{ text: 'Consolidado' }">
          <kendo-chart-series>

            <kendo-chart-series-item type="radarLine" name="última lectura"
              [data]="signosVitales | signosVitales:'consolidado'" field="values" categoryField="name">
            </kendo-chart-series-item>

          </kendo-chart-series>
          <kendo-chart-tooltip></kendo-chart-tooltip>

          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item>
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-legend position="bottom"></kendo-chart-legend>
        </kendo-chart>
      </div>
    </kendo-stacklayout>


  </kendo-stacklayout>


</kendo-dialog>



<kendo-dialog class="kendo-dialog-resultado" [title]="tituloPopUp" *ngIf="windowOpened" maxWidth="95%" minWidth="95%"
  maxHeight="95%" minHeight="95%" (close)="closeWindow('cancel')">
  <div>
    <app-redirect-component [inputHost]="windowUrl">

    </app-redirect-component>
  </div>


</kendo-dialog>