export interface ConfiguracionUsuarioDTO {
    idConfiguracion: string;
    data: string;    
}

export const ConfiguracionUsuarioKeys = {
    Dashboard: {
      Layout: "Dashboard.Layout",
      ItemsRemoved: "Dashboard.ItemsRemoved",
    },
  };
  