<div class="board bg-dashboard dark:bg-neutral-800" id="mainBoardPage">

  <div class="">
    <div class="flex justify-between items-center bg-white p-1 dark:bg-oscuro-alternativo">
      <button *ngIf="isEditable" [disabled]="isSaving" class="text-primary dark:text-white hover:underline py-1 px-2"
        (click)="windowOpenedLibrary = true">
        <i class="fa-duotone fa-square-dashed-circle-plus mr-1"></i>Agregar desde la biblioteca
      </button>

      <div class="flex-grow"></div>

      <div *ngIf="isEditable" class="flex items-center">
        <div *ngIf="isSaving" class="animate-pulse h-5 w-5 bg-primary rounded-full mr-2"></div>

        <button [disabled]="isSaving" class="text-primary hover:underline py-1 px-2 dark:text-white"
          (click)="cancel()">
          <i class="fa-duotone fa-xmark mr-1"></i>Cancelar
        </button>

        <button [disabled]="isSaving" class="text-primary hover:underline py-1 px-2 dark:text-white"
          (click)="saveDashboard()">
          <i class="fa-duotone fa-floppy-disk mr-1"></i>Guardar
        </button>
      </div>

      <div class="flex items-center">
        <button *ngIf="!isEditable" class="text-primary hover:underline py-1 px-2 dark:text-white"
          [disabled]="isEditable" (click)="isEditable = true; emitirModoEdicion()">
          <i class="fa-duotone fa-square-dashed-circle-plus mr-1"></i>Personalizar
        </button>
      </div>
    </div>
  </div>


  <kendo-tilelayout #tilelayout class="dashboard-tilelayout" autoFlow="none" [columns]="50" [gap]="10" [rowHeight]="10"
    [reorderable]="true" [resizable]="isEditable" (reorder)="onReorder($event)" (resize)="onResize($event)">

    <ng-container *ngIf="tracks.length > 0">
      <ng-container *ngFor="let track of tracks">
        <ng-container *ngFor="let item of track.items ">

          <kendo-tilelayout-item [hidden]="item['hide']" [id]="item.id" class="dashboard-tilelayout-item"
            [ngClass]="{'dashboard-tilelayout-item-editing': isEditable, 'dashboard-tilelayout-item-initial': !isEditable }"
            [reorderable]="isEditable && (item['reorderable'] == null || item['reorderable'])"
            [colSpan]="item?.colSpan ? item.colSpan : 50" [rowSpan]="item?.rowSpan ? item.rowSpan : 36">



            <kendo-tilelayout-item-header style="padding-right: 0;">


              <div class="row">
                <div>
                  <i class="pt-2" *ngIf="item?.icon" [class]="item?.icon"></i>&nbsp; {{item?.title}}
                </div>
                <div>
                  <button kendoButton fillMode="clear" (click)="ShowFilter(item)" *ngIf="item?.includeFilter">
                    <i class="fa-solid fa-filter"></i>
                  </button>

                  <button kendoButton fillMode="clear" #targetSettings class="target" class="setting-item"
                    (mouseenter)="item['reorderable'] = false" (mouseleave)="item['reorderable'] = true"
                    [hidden]="!isEditable">
                    <i class="fa-solid fa-gear"></i>
                  </button>

                  <kendo-contextmenu #menuSetting [target]="targetSettings"
                    [items]="[{nombre :'Ocultar del dashboard'}]" showOn="click">

                    <ng-template kendoMenuItemTemplate let-itemMenu="item">
                      <div style="display: block;width: 100%;" (click)="hideItem(item)">
                        <i class="fa-solid fa-trash"></i>
                        {{ itemMenu.nombre }}
                      </div>
                    </ng-template>

                  </kendo-contextmenu>


                </div>

              </div>

            </kendo-tilelayout-item-header>
            <div class="max-h-full pb-10"> 
              <ng-template appDashboardOutlet [item]="item"></ng-template>
            </div>


            <span [ngClass]="{'react-resizable-handle': isEditable}" style="touch-action: none;"></span>
          </kendo-tilelayout-item>


        </ng-container>


      </ng-container>
    </ng-container>
  </kendo-tilelayout>


  <kendo-dialog class="kendo-dialog-resultado" title="Tarjetas de información" *ngIf="windowOpenedLibrary"
    maxWidth="50%" minWidth="50%" maxHeight="300px" minHeight="300px" (close)="closeWindowLibrary('cancel')">
    <kendo-listview [data]="dashboardList" containerClass="k-d-flex k-flex-col k-flex-nowrap">
      <ng-template kendoListViewHeaderTemplate>
        <div class="header">Lista de tarjetas de tu perfil</div>
      </ng-template>
      <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
        <div class="contact" [class.border-top]="!isFirst">

          <i class="fa-solid fa-chart-simple"></i>


          <div class="contact-details">
            <div class="contact-name">{{dataItem.nombre}}</div>
            <div class="contact-descripcion">{{dataItem.descripcion}}</div>
          </div>


          <button kendoButton [disabled]="dataItem.exist" size="small" rounded="small" themeColor="primary"
            (click)="AddItem(dataItem)">
            Agregar
          </button>


        </div>


      </ng-template>

    </kendo-listview>

  </kendo-dialog>

</div>