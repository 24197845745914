import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  ViewChild,
} from '@angular/core';
import { Track } from '../../../models/track';
import { DashboardOutletDirective } from '../../../dashboard-outlet.directive';
import { Item } from '../../../models/item';
import { dashboardCards } from '../../../dashboard-cards';
import { DashboardContainer } from './dashboard.container';
//import { DashboardCards } from '../../../dashboard-cards.enum';
import { TileLayoutComponent, TileLayoutItemComponent, TileLayoutReorderEvent, TileLayoutResizeEvent } from '@progress/kendo-angular-layout';
import { dashboardSettings } from './dashboard.settings';
import { HomeDTO } from '@src/app/models/Home/HomeDTO';
import { DashboardAsistencialService } from '../../../components/dashboard-asistencial/services/dashboard-asistencial.service';
import { PersistenceService } from '@src/app/services/Utilities/data.persistence.service';
import { LocalStorageList } from '@src/app/helpers/enums/enums.keys';
import { ConfiguracionUsuarioKeys, ConfiguracionUsuarioDTO } from '@src/app/models/General/ConfiguracionUsuario';
import { AuthenticationService } from '@src/app/services/Security/authentication.service';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChildren(DashboardOutletDirective) dashboardOutlet: QueryList<DashboardOutletDirective>;

  @Input() dashboardData: HomeDTO[] = [];
  @Input() isEditable = false;
  @ViewChild('tilelayout', { static: true }) tilelayout: TileLayoutComponent;
  dataConfiguracion = [];
  isLoading = false
  instanceGroup = {};
  isSaving = false
  tracks: Array<Track> = [];
  layoutDerty = false;
  windowOpenedLibrary = false
  test = {}

  constructor(private cd: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    private persistenceService: PersistenceService) {
     

        
  }

  ngOnInit() {
    let trackFilter = JSON.parse(JSON.stringify(dashboardSettings)) as Track[];

    let mapTrack = trackFilter.map(t => {
        t.items= t.items.filter(item => this.dashboardData.some(s=>s.selector == item.id) || item.developer === true);
        return t
      });
    
        this.LoadConfiguracion(mapTrack);
        this.tracks = mapTrack; 
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadContents()
    }, 0);
  }

  public closeWindowLibrary(status: string): void { 
    this.windowOpenedLibrary = false;
  }

  LoadConfiguracion(mapTrack) {


    this.dataConfiguracion = this.persistenceService.getData(LocalStorageList.configuracionUsuario, true);

    if (this.dataConfiguracion) {

      let layout = this.dataConfiguracion.find(s => s.idConfiguracion == ConfiguracionUsuarioKeys.Dashboard.Layout) as ConfiguracionUsuarioDTO;
      let itemsRemoved = this.dataConfiguracion.find(s => s.idConfiguracion == ConfiguracionUsuarioKeys.Dashboard.ItemsRemoved) as ConfiguracionUsuarioDTO;

      //organizacion del dashboard
      let layoutData = []
      if (layout) {
         layoutData = JSON.parse(layout.data);
      }

      mapTrack.forEach(track => {


        if (track.items.length > 0) {
          track.items[0]["hide"] = false

          if (layoutData.some(s => s.id == track.items[0].id)) {
            var config = layoutData.find(s => s.id == track.items[0].id);
            track.items[0].colSpan = config.colSpan;
            track.items[0].rowSpan = config.rowSpan;
          }

        }
      });


      //items ocultos
      if (itemsRemoved) {
        let itemData = JSON.parse(itemsRemoved.data);
        mapTrack.forEach(track => {
          // const updatedItems = track.items.filter(item => !itemData.includes(item.id));

          track.items.filter(item => itemData.includes(item.id)).forEach(element => {
            element["hide"] = true;
          })


          //     track.items = updatedItems;
        });
      }


    }

  }

  //comunicarle a todos los componentes asignados que esta en modo edicion
  emitirModoEdicion() {
    this.tracks.find(track => {
      track.items.forEach(track => {

        this.instanceGroup[track.component].isEditable = this.isEditable;
      })
    })
  }

  public onReorder(e: TileLayoutReorderEvent): void {
    this.layoutDerty = true;
  }

  public onResize(e: TileLayoutResizeEvent): void {
    this.layoutDerty = true;
  }
  async AddItem(itemMenu: any) {

    this.dataConfiguracion = this.persistenceService.getData(LocalStorageList.configuracionUsuario, true);
    if (this.dataConfiguracion) {
      let itemsRemoved = this.dataConfiguracion.find(s => s.idConfiguracion == ConfiguracionUsuarioKeys.Dashboard.ItemsRemoved) as ConfiguracionUsuarioDTO;
      if (itemsRemoved) {

        let itemsData = JSON.parse(itemsRemoved.data);
        const updatedItems = itemsData.filter(item => item != itemMenu.selector);
        await this.dashboardService.GuardarDashboardItemsRemoved(JSON.stringify(updatedItems));
        await this.authenticationService.ConfiguracionPorUsuario(false);

        this.ngOnInit()
        this.ngAfterViewInit()
      }

    }

  }

  get dashboardList() { 
    const modifiedDashboards = this.dashboardData.map(dashboard => {
      const trackItem = this.tracks.find(track => {
        let itemsRemoved = this.dataConfiguracion.find(s => s.idConfiguracion == ConfiguracionUsuarioKeys.Dashboard.ItemsRemoved) as ConfiguracionUsuarioDTO;
        if (itemsRemoved && itemsRemoved.data) {
          let itemsData = JSON.parse(itemsRemoved.data);
          return track.items.find(item => item.id === dashboard.selector && !itemsData.includes(item.id));
        }
        else {
          return track.items.find(item => item.id === dashboard.selector);
        }

      });
      if (trackItem) {
        return { ...dashboard, exist: true };
      }
      return dashboard;
    });

    return modifiedDashboards;
  }

  async hideItem(itemMenu: any) {

    this.dataConfiguracion = this.persistenceService.getData(LocalStorageList.configuracionUsuario, true);
    if (this.dataConfiguracion) {

      let layout = this.dataConfiguracion.find(s => s.idConfiguracion == ConfiguracionUsuarioKeys.Dashboard.ItemsRemoved) as ConfiguracionUsuarioDTO;

      if (layout) {

        let layoutData = JSON.parse(layout.data);
        if (layoutData) {
          layoutData.push(itemMenu.id);
          itemMenu["hide"] = true;
          await this.dashboardService.GuardarDashboardItemsRemoved(JSON.stringify(layoutData));
          await this.authenticationService.ConfiguracionPorUsuario(false);
        }

      } else {
        let itemsRemoved = [itemMenu.id];
        itemMenu["hide"] = true;

        await this.dashboardService.GuardarDashboardItemsRemoved(JSON.stringify(itemsRemoved));
        await this.authenticationService.ConfiguracionPorUsuario(false);
      }

      this.dataConfiguracion = this.persistenceService.getData(LocalStorageList.configuracionUsuario, true);

    }


  }

  cancel(){

    this.isEditable = false;
    this.emitirModoEdicion()
  }

  async saveDashboard() {

    if (!this.layoutDerty) {
      this.isEditable = false;

      this.emitirModoEdicion()

      return;
    }


    this.isSaving = true;
    // Obtiene todos los elementos hijo del elemento <kendo-tilelayout>
    const tilelayoutChildren = this.tilelayout.items;

    // Recorre cada elemento hijo

    let lsData = []
    tilelayoutChildren.forEach((element: TileLayoutItemComponent) => {

      let id = element.elem.nativeElement.id;
      let colSpan = element.colSpan;
      let rowSpan = element.rowSpan;

      let data = { id: id, colSpan: colSpan, rowSpan: rowSpan };

      lsData.push(data)

    });


    await this.dashboardService.GuardarDashboardLayout(JSON.stringify(lsData));

    await this.authenticationService.ConfiguracionPorUsuario(false);


    this.isSaving = false;
    this.layoutDerty = false;
    this.isEditable = false;

    this.emitirModoEdicion()

  }

  loadContents = () => {
    if (!this.dashboardOutlet || !this.dashboardOutlet.length) {

      return;
    }

    this.dashboardOutlet.forEach(template => {
      this.cd.detectChanges();
      this.loadContent(template, template.item);
    });
    this.cd.detectChanges();
  };

  loadContent = (template: DashboardOutletDirective, item: Item) => {
    if (!item.component) {

      return;
    }

    const viewContainerRef = template.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(dashboardCards[item.component]);

    const instance = componentRef.instance as DashboardContainer;
    instance.item = item;
    //instance.isEditable = isEditable;
    this.instanceGroup[item.component] = instance;

  };

  /**
   * mostrar u ocultar los filtros
   * @param item item
   */
  ShowFilter(item) {

    this.instanceGroup[item.component].showFilters = !this.instanceGroup[item.component].showFilters;
  }

  Maximizer(firstItem: any) {
    firstItem.colSpan = 10;
    firstItem.rowSpan = 30;
  }


  private log(
    event: TileLayoutReorderEvent | TileLayoutResizeEvent,
    eventName: string
  ): void {
    // Check the developer tools console to inspect the full available event data
    // console.log(eventName, event);

    delete event.items;
    delete event.item;
    console.log(`${eventName}: ${JSON.stringify(event)}`);
  }
}


