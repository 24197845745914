import { Component, EventEmitter, Output, ViewChild } from '@angular/core'; 
import { DashboardContainer } from '@src/app/core/dashboard/core/dashboard/components/dashboard.container';


@Component({
  template: `
    <dashboard-asistencial #component [ShowFilters] = "showFilters" [isEditable] = "isEditable"  ></dashboard-asistencial>
  `,
})
export class DashboardAsistencialContainer extends DashboardContainer {
  showFilters: boolean = false;  
  isEditable: boolean = false;;
   
}
 

