
import { HelloWorldContainer } from './components/hello-world/hello-world.container'; 
import { DashboardAsistencialContainer } from './components/dashboard-asistencial/components/dashboard-asistencial.container';
import { DashboardIncidenciasContainer } from './components/dashboard-incidencias/components/dashboard-incidencias.container';

//enumerados de tarjetas
export enum DashboardCards {
  Asistencial = 'Asistencial',
  Incidencias = 'Incidencias',
  HELLO_WORLD = 'HELLO_WORLD',
}

//contenedor de tarjetas
export const dashboardCards = {
  HELLO_WORLD: HelloWorldContainer,
  Asistencial: DashboardAsistencialContainer,
  Incidencias: DashboardIncidenciasContainer
};


