import { Component, EventEmitter, Output, ViewChild } from '@angular/core'; 
import { DashboardContainer } from '@src/app/core/dashboard/core/dashboard/components/dashboard.container';
 

@Component({
  template: `
    <dashboard-incidencias #component  ></dashboard-incidencias>
  `,
})
export class DashboardIncidenciasContainer extends DashboardContainer {
  showFilters: boolean = false;  
  isEditable: boolean = false;;
   
}
 

