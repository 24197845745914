import { AfterContentInit, Component, OnInit } from '@angular/core';
import { DashboardIncidenciasService } from '../services/dashboard-incidencias.service';
import { DashboardIncidenciasDto } from '@src/app/models/Dashboard/dasboard-incidencias/DashboardIncidenciasDto';
import { TimerService } from '@src/app/services/Generals/splus.timer.service';

@Component({
  selector: 'dashboard-incidencias',
  templateUrl: './dashboard-incidencias.component.html',
  styleUrls: ['./dashboard-incidencias.component.css']
})
export class DashboardIncidenciasComponent implements  AfterContentInit {
  isLoading = false;
  errorData = false;
  idInterval;
  constructor(
    private readonly timerService: TimerService,
    private dashboardIncidenciasService: DashboardIncidenciasService) { }

    ngAfterContentInit(): void {
    this.isLoading = true;
    this.Init()
    // Se cambia ejecucion de incidencias de 6 seg a 2 min por peticion de isa el 26/03/204 bajo el Bug 2299
    this.timerService.startSubscription('tarjeta-incidencia', 120000, () => {
      this.Init();
    });


  }

  trackByFn(index, item) {
    return item.id; // Devuelve un identificador único para cada elemento
  }

  filteredData = {
    hoy: [],
    ayer: [],
    dosDias: [],
    unaSemana: []
  };

  ngOnDestroy() {
    this.timerService.stopSubscription('tarjeta-incidencia');
  }


  Init() {



    this.dashboardIncidenciasService.ObtenerDashBoard<DashboardIncidenciasDto[]>().subscribe({
      next: (data) => {

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const twoDaysAgo = new Date(today);
        twoDaysAgo.setDate(today.getDate() - 2);
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(today.getDate() - 7);

        if (data.isSuccessful) {

          this.filteredData.hoy = data.result.filter(item => this.isSameDay(item.fecha, today));
          this.filteredData.ayer = data.result.filter(item => this.isSameDay(item.fecha, yesterday));
          this.filteredData.dosDias = data.result.filter(item => this.isSameDay(item.fecha, twoDaysAgo));
          this.filteredData.unaSemana = data.result.filter(item => this.isWithinOneWeek(item.fecha, today));

         // console.log(data.result);
         // console.log( this.filteredData);

        }

        this.isLoading = false;
      }
    });


  }
  isSameDay(dateString: string, compareDate: Date): boolean {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    return date.getFullYear() === compareDate.getFullYear() &&
           date.getMonth() === compareDate.getMonth() &&
           date.getDate() === compareDate.getDate();
  }

  isWithinOneWeek(dateString: string, compareDate: Date): boolean {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    const oneWeekAgo = new Date(compareDate);
    oneWeekAgo.setDate(compareDate.getDate() - 7);
    return date >= oneWeekAgo && date <= compareDate;
  }
}
