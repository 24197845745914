import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaludPlusApiResponse } from '@src/app/models/base/SaludplusApiResponse';
import { ConfiguracionUsuarioKeys, ConfiguracionUsuarioDTO } from '@src/app/models/General/ConfiguracionUsuario';
import { RequestResult } from '@src/app/models/General/RequestModel';
import { ControllerApiList } from '@src/app/models/SaludPlus/Controller/ControllerList';
import { lastValueFrom, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  async GuardarDashboardItemsRemoved(data: string,): Promise<RequestResult> {
    let setData: ConfiguracionUsuarioDTO = {
      data: data,
      idConfiguracion: ConfiguracionUsuarioKeys.Dashboard.ItemsRemoved
    } 
    const result = this.http
      .post<any>(
        `${ControllerApiList.General.General}/GuardarConfiguracionUsuario`,
        setData
      ) 
    const dataResponse = await lastValueFrom(result);
    return dataResponse;
  }


  async GuardarDashboardLayout(data: string,): Promise<RequestResult> {
    let setData: ConfiguracionUsuarioDTO = {
      data: data,
      idConfiguracion: ConfiguracionUsuarioKeys.Dashboard.Layout
    } 
    const result = this.http
      .post<any>(
        `${ControllerApiList.General.General}/GuardarConfiguracionUsuario`,
        setData
      ) 
    const dataResponse = await lastValueFrom(result);
    return dataResponse;
  }

  
}
