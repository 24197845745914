
<div class="loading-card" *ngIf="isLoading">
  <span class="loading-card-container" role="progressbar" aria-label="Loading">
    <span class="css-uno-barra-loading-l"></span>
    <span class="css-dos-barra-loading-l"></span>
    <span class="css-tres-barra-loading-l"></span>
    <span class="css-cuatro-barra-loading-l"></span>
  </span>
  </div>

  
<div class="flex flex-wrap  md:flex-col pt-1 pb-1 ">
<div id="container"  class="space-y- relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px   md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
  <!-- Item #1 -->
  <div class="relative " >
    <div class="md:flex items-center md:space-x-4 mb-3">
      <div class="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
        <!-- Icon -->
        <div class="flex items-center justify-center w-10 h-10   ">
      
          <span class="relative flex h-2 w-2">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-700 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-2 w-2 bg-green-600"></span>
          </span>
        </div>
        <!-- Date -->

      </div>
      <!-- Title -->
      <div class="text-slate-500 ml-14 "><span class="text-slate-900 font-bold dark:text-white">Hoy</span>
      </div>

    </div>
    <!-- Card -->
    <div *ngFor="let item of filteredData.hoy; ; trackBy: trackByFn;let first = first;"
    [ngClass]="{'fade-up' : first }"   class=" bg-white  dark:bg-oscuro-alternativo p-4 rounded border mb-1 border-slate-200 dark:border-slate-600 text-slate-500 dark:text-white   ml-8 md:ml-10 mr-2 ">
      <div class="normal-case  text-slate-800 dark:text-white bold ">
         {{item.proceso | titlecase}}
        <span
          class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">{{
          item.tipoDocumento | titlecase}}</span>
        <span
          class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300  ">{{item.numeroDocumento}}</span>

      </div>
      <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-400 ">
        <i class="fa-solid fa-calendar-check "></i> {{item.fecha}}</time>
      <p>
        {{item.descripcion}}
      </p>
    </div>


  </div>

  <!-- Item #2 -->
  <div class="relative ">
    <div class="md:flex items-center md:space-x-4 mb-3">
      <div class="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1 dark:bg-oscuro-alternativo">

          <div class="h-2 w-2 rounded-full bg-blue-500"></div>

        </div>
        <!-- Date -->
        <!--<time class="font-caveat font-medium text-xl text-indigo-500 md:w-28">Apr 7, 2024</time>-->
      </div>
      <!-- Title -->
      <div class="text-slate-500 ml-14 dark:text-white"> Ayer
      </div>
    </div>
    <!-- Card -->
    <div *ngFor="let item of filteredData.ayer"
    class="bg-white dark:bg-oscuro-alternativo p-4 rounded border mb-1 border-slate-200 dark:border-slate-600 text-slate-500 dark:text-white   ml-8 md:ml-10 mr-2 ">
    <div class="normal-case  text-slate-800 dark:text-white bold">
       {{item.proceso | titlecase}}
      <span
        class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">{{
        item.tipoDocumento | titlecase}}</span>
      <span
        class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300  ">{{item.numeroDocumento}}</span>

    </div>
    <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-400 ">
      <i class="fa-solid fa-calendar-check "></i> {{item.fecha}}</time>
    <p>
      {{item.descripcion}}
    </p>
  </div>
  </div>

  <!-- Item #3 -->
  <div class=" relative">
    <div class="md:flex items-center md:space-x-4 mb-3">
      <div class="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1 dark:bg-oscuro-alternativo">
          <i class="fa-solid fa-calendar-day text-slate-500 dark:text-white"></i>
        </div>
        <!-- Date -->
        <!--<time class="font-caveat font-medium text-xl text-indigo-500 md:w-28">Apr 7, 2024</time>-->
      </div>
      <!-- Title -->
      <div class="text-slate-500 ml-14 dark:text-white"> Hace 2 días</div>
    </div>
    <!-- Card -->
    <div *ngFor="let item of filteredData.dosDias"
    class="bg-white dark:bg-oscuro-alternativo p-4 rounded border mb-1 border-slate-200 dark:border-slate-600 text-slate-500 dark:text-white   ml-8 md:ml-10 mr-2 ">
    <div class="normal-case  text-slate-800 dark:text-white bold">
       {{item.proceso | titlecase}}
      <span
        class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">{{
        item.tipoDocumento | titlecase}}</span>
      <span
        class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300  ">{{item.numeroDocumento}}</span>

    </div>
    <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-400 ">
      <i class="fa-solid fa-calendar-check "></i> {{item.fecha}}</time>
    <p>
      {{item.descripcion}}
    </p>
  </div>
  </div>

  <!-- Item #4 -->
  <div class=" relative">
    <div class="md:flex items-center md:space-x-4 mb-3">
      <div class="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow md:order-1 dark:bg-oscuro-alternativo">
          <i class="fa-solid fa-calendar-day text-slate-500 dark:text-white"></i>
        </div>
        <!-- Date -->
        <!--<time class="font-caveat font-medium text-xl text-indigo-500 md:w-28">Apr 7, 2024</time>-->
      </div>
      <!-- Title -->
      <div class="text-slate-500 ml-14 dark:text-white">
        <!--<span class="text-slate-900 font-bold">Hace 1 semana</span>--> Hace 1 semana
      </div>
    </div>
    <!-- Card -->
    <div *ngFor="let item of filteredData.unaSemana"
    class="bg-white dark:bg-oscuro-alternativo p-4 rounded border mb-1 border-slate-200 dark:border-slate-600 text-slate-500 dark:text-white   ml-8 md:ml-10 mr-2 ">
    <div class="normal-case  text-slate-800 dark:text-white bold">
       {{item.proceso | titlecase}}
      <span
        class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">{{
        item.tipoDocumento | titlecase}}</span>
      <span
        class="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300  ">{{item.numeroDocumento}}</span>

    </div>
    <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-400 ">
      <i class="fa-solid fa-calendar-check "></i> {{item.fecha}}</time>
    <p>
      {{item.descripcion}}
    </p>
  </div>
  </div>



</div>
</div>