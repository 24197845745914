//import { DashboardCards } from "../../../dashboard-cards.enum";
import { DashboardCards } from "../../../dashboard-cards";
import { Track } from "../../../models/track";

export const dashboardSettings : Array<Track> = [
    {
      items: [
        { 
          id: 'dashboard-asistencial',
          title: 'Camas',
          icon: 'fa-solid fa-bed',
          component: DashboardCards.Asistencial,
          includeFilter: true,
          colSpan : 50,
          rowSpan : 36
        },  
      ],
    },
    {
      items: [
        { 
          id: 'dashboard-incidencias',
          title: 'Incidencias',
          icon: 'fa-duotone fa-light-emergency-on',
          component: DashboardCards.Incidencias,
          includeFilter: false,
          colSpan : 50,
          rowSpan : 36
        },  
      ],
    },
    {
      items: [
        {
          id: 'hello-world-2',          
          title: 'Hello World 2',
          icon: 'fa fa-globe',
          component: DashboardCards.HELLO_WORLD,
          developer: false     
        },
      ],
    },
  ];
